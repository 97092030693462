.modal {
  width: 700px;
}
.content {
  border-radius: 4px;
  .dropZone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: var(--color-light-cement);
    border-radius: 4px;
    border: dashed #a1aeb7 2px;
    padding: 2.5em 0px;
  }
}
.titleStyle {
  color: var(--color-night);
}

.fileDetails {
  margin-top: 20px;
  margin-left: 0;
  color: var(--color-gray);
  > div {
    width: 50%;
  }
  .fileDetailsList {
    font-weight: 500px;
    color: var(--color-blue-gray);
  }
}
